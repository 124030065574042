import React, { Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Logo from "@/images/new/triplice/logo.svg";
import Img1 from "@/images/new/triplice/bgCity.png";
import City from "@/images/new/triplice/city.png";
import Lupa from "@/images/new/triplice/lupa.png";
import Itaipu from "@/images/new/triplice/itaipu.svg";
import Sapientia from "@/images/new/landingPageEs/sapientia.svg";
import Vertere from "@/images/new/landingPageEs/vertere.svg";
import Fractal from "@/images/new/landingPageEs/fractal.svg";
import Regulamento from '../assets/documents/Olimpiada-Triple-Frontera.pdf'
// function onChange(value) {
//   console.log("Captcha value:", value);
// }

const Finalistas = ({}) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <Fragment>
      <section
        className="pageLanding"
        style={{ background: "#e5e5e5", paddingBottom: 150, paddingTop: 80 }}
      >
        <div className="container" style={{ position: "relative" }}>
          <div className="lang-menu">
            <ul>
              <li>Idiomas:</li>
              <li>
                <a href="/triplice-fronteira" >
                  PT
                </a>
              </li>
              <li>
                <a href="#" className="selected">ES</a>
              </li>
            </ul>
          </div>

          <div className="row" data-aos="fade-right" data-aos-once="true">
            <img src={Logo} className="MR-M-16" />
          </div>
          <div className="img-header">
            <div className="city">
              <img className="img-size" src={City} />
            </div>
            <img className="img-size" src={Img1} />
            <div className="shadow-city"></div>
          </div>
          <div className="row">
            {/* <div className="col-lg-12 d-none-img-mob">
              <img src={Img1} style={{ marginBottom: 32 }} />
            </div> */}
            <div className="img-header d-none-img-mob">
              <div className="city">
                <img className="img-size" src={City} />
              </div>
              <img className="img-size" src={Img1} />
              <div className="shadow-city"></div>
            </div>
            <div
              className="col-lg-5 col-sm-12"
              data-aos="fade-right"
              data-aos-once="true"
            >
              <div className="row">
                <div className="header_content PT-32">
                  <p>
                  Unas olimpiadas para  estudiantes y professores de la regíon fronteriza entre Argentina, Brasil y Paraguay que quieran poner en práctica sus ideas sobre el desarrolho sostenible.
                  </p>
                </div>
                <div className="cronograma">
                  <h5>Línea de tiempo</h5>
                  <p>
                  Inscripciones: <strong>hasta el 26 de febrero</strong>
                    {/* <br />
                    Entrega dos projetos: <strong>25 e 26 de novembro</strong>
                    <br />
                    Divulgação dos finalistas: <strong>28 de novembro</strong>
                    <br />
                    Apresentação final: <strong>2 de dezembro</strong> */}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-7 d-none-img-desk" data-aos="fade-left" data-aos-once="true">
              <img className="img-size" src={Img1} style={{ width: '100%' }} />
            </div> */}
          </div>
        </div>
      </section>
      <section
        className="section-blue"
        style={{ background: "#5287DD", padding: "72px 0" }}
        data-aos="fade-up"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>Iniciativa del Instituto Vertere</h2>
              <p style={{ marginBottom: 0 }}>
              Somos una  filial olímpica de Sapientia, las Olimpiadas del Futuro. Sapientia es una iniciativa llevada a cabo por Instituto Vertere y se basa em la Agenda de 2030 de las Naciones Unidas – ONU. La competencia aborda las disciplinas estudiadas en el aula desde una perspectiva conectada com los problemas de nuestro tempo y enriquecida por la visión de los pensadores del siglo 21. 
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section-gray"
        style={{ background: "#e5e5e5", padding: "72px 0" }}
        data-aos="fade-up"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>Intercambio de aprendizaje</h2>
              <p>
              Llegamos a la tríplice Frontera para celebrar la expansión de las Olimpiadas del Instituto Vertere a los países de habla hispana de America del Sur!

              </p>
              <a style={{ padding: "14px 22px" }} className="cta-inscricao" href="https://app.olimpiadadofuturo.com.br/">
              Inscripciones abiertas hasta el 01 de noviembre
              </a>
              <a className="cta-regulamento" href={Regulamento} target='_blank'>
              Accede al reglamento.

              </a>
            </div>
            <div className="col-lg-6">
              <img src={Lupa} className="lupa" />
            </div>
          </div>
        </div>
      </section>
      <section
        className="section-blue text-center"
        style={{ background: "#5287DD", padding: "72px 0" }}
        data-aos="fade-up"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 style={{ marginBottom: 32 }}>
              Inscribite 
                <br />
                Es Gratis!
              </h2>
              <a className="cta-inscricao" href="https://app.olimpiadadofuturo.com.br/">
              QUIERO INSCRIBIRME
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{ background: "#e5e5e5", padding: "60px 0" }}
        data-aos="zoom-in-down"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 footer">
              <ul className="footer-logos">
                <li>Realización:</li>
                <li>
                  <a href="https://olimpiadadofuturo.com.br/" target="_blank">
                    <img src={Sapientia} />
                  </a>
                </li>
                <li>
                  <a href="https://institutovertere.org/" target="_blank">
                    <img src={Vertere} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-sm-12 footer">
              <ul className="footer-logos">
                <li>Apoyo:</li>
                <li>
                  <a href="https://www.itaipu.gov.br/" target="_blank">
                    <img src={Itaipu} />
                  </a>
                </li>
                <li>
                  <a href="https://fractaltecnologia.com.br/" target="_blank">
                    <img src={Fractal} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Finalistas;
